import React from "react"
import Layout from '../components/layout'
// import { Link } from "gatsby"

import SectionHead from "../components/sectionHead"

const Jacyko = (props) => {
    return (
        <Layout>
            <div className="content-narrow">
                <SectionHead text="Jacyko is 誰" />
				<p>
					このブログエンジンを書き直したプログラマーです。永遠の16歳。
				</p>
            </div>
        </Layout>
    )
}

export default Jacyko